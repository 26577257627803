import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-5" }
const _hoisted_2 = { class: "d-flex align-items-center rounded py-5 px-5 bg-light-warning" }
const _hoisted_3 = { class: "svg-icon svg-icon-3x svg-icon-warning me-5" }
const _hoisted_4 = { class: "text-gray-600 fw-bold fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("span", _hoisted_3, [
        _createVNode(_component_inline_svg, { src: "media/icons/duotone/Code/Info-circle.svg" })
      ]),
      _createVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}