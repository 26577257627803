
import { defineComponent } from "vue";
import { themeName } from "@/core/helpers/documentation";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "spacing",
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      themeName
    };
  }
});
