
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Overview from "@/views/resources/documentation/base/utilities/Overview.vue";
import ThemeColor from "@/views/resources/documentation/base/utilities/ThemeColor.vue";
import Spacing from "@/views/resources/documentation/base/utilities/Spacing.vue";
import FontSize from "@/views/resources/documentation/base/utilities/FontSize.vue";
import LineHeight from "@/views/resources/documentation/base/utilities/LineHeight.vue";
import Opacity from "@/views/resources/documentation/base/utilities/Opacity.vue";
import WidthAndHeight from "@/views/resources/documentation/base/utilities/WidthAndHeight.vue";
import ZIndex from "@/views/resources/documentation/base/utilities/ZIndex.vue";

export default defineComponent({
  name: "utilities",
  components: {
    Overview,
    ThemeColor,
    Spacing,
    FontSize,
    LineHeight,
    Opacity,
    WidthAndHeight,
    ZIndex
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Utilities");
    });
  }
});
